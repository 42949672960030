import React, { Component } from 'react';
import Identifier from './Identifier';
import InputDate from './InputDate';

class UserForm2 extends Component {

  render() {
    
    return (
      <>
        <Identifier
          index={this.props.index}
          user={this.props.user}
          updateUser={this.props.updateUser}
        />
        <InputDate
          index={this.props.index}
          updateBirthdate={this.props.updateBirthdate}
          birthdate={this.props.user.birthdate}
        />

        <div className="form-group">
          <label htmlFor="phone" className="col-form-label">
            Téléphone *
          </label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            name="phone"
            required
            value={this.props.user.phone}
            onChange={(e) => {
              this.props.updatePhone(e.target.value, this.props.index)
            }}
          />
        </div>

        {/*<div className="form-group">
          <label htmlFor={`inputEmail${this.props.index}`} className="col-form-label">
            Email <small>(optionnel)</small>
          </label>

          <input type="email"
                 className="form-control"
                 name="email"
                 id={`inputEmail${this.props.index}`}
                 placeholder="Entrer email"
                 value={this.props.user.email}
                 onChange={e => { this.props.onChange(e, this.props.index) }}
           />
        </div>*/}
      </>
    )
  }
}

export default UserForm2;
