import React, { Component } from 'react';
import logo from '../assets/img/logo-h290.png';
import AuthContext from '../AuthContext'

// functional
class Login extends Component {
  static contextType = AuthContext
 

  constructor() {
    super();
    this.state = {
      didMount: false
    }
    this.signInButtonWrapperRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({didMount: true})
    }, 0);
    this.context.renderSignInButton(this.signInButtonWrapperRef.current)
  }

  render() {
    return (
      <main role="main" className="container full-height d-flex flex-column">
          <div className="signin-logo-wrapper mt-md-5">
            <img src={logo} className={`signin-logo fade-in ${this.state.didMount && 'visible'}`} alt="evolution verticale logo"/>
          </div>
          <div id="signin" ref={this.signInButtonWrapperRef}></div>
      </main>
    )
  }
}

export default Login;
