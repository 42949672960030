import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import HttpsRedirect from 'react-https-redirect';
import * as serviceWorker from './serviceWorker';
//import swConfig from './swConfig';
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/offcanvas.css';
import './assets/css/index.css';
//import 'react-date-range/dist/styles.css'; // main style file
//import 'react-date-range/dist/theme/default.css'; // theme css file

ReactDOM.render(
    <Router>
      <Route component={App} />
    </Router>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

/*
<HttpsRedirect>
  <Router>
    <Route component={App} />
  </Router>
</HttpsRedirect>
*/
