import React, { Component } from 'react';
import { Route, Redirect } from "react-router-dom";
import Login from './components/Login';
import Auth from './Auth';
import Wrapper from './Wrapper';
import AuthContext from './AuthContext';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      auth: new Auth(this.props.history)
    }
  }

  render() {
    const { auth } = this.state;
    return (
      <AuthContext.Provider value={auth}>
        <Route
          path="/"
          render={props =>
            auth.isSignedIn() ? (
              <Wrapper auth={this.auth} {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          path="/login"
          render={props =>
            auth.isSignedIn() ? (
              <Redirect to="/" />
            ) : (
              <Login {...props} />
            )
          }
        />
      </AuthContext.Provider>
    );
  }
}

export default App;
