import React, { Component } from 'react';
//import $ from "jquery";
import AuthContext from '../AuthContext';

class Custom extends Component {

  constructor(props) {
    super(props);
    this.state = {
      article: "",
      price: "",
      description: ""
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const url = process.env.REACT_APP_API_URI + "/api/shop/sold.php";
    const worker = this.context.getUserName();
    const entry = Object.assign( {}, this.state, {worker: worker} );

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(entry),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.context.getAccessToken()}`
      },
    })
    .then(response => {
    
      this.props.updateHistory();

      if(response.ok){
        //console.log(response.status)
        this.sucessNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);
          
      }else if(response.status === 401){
        //console.log(response.status)
        this.context.logOut();
      }else {
        //console.log(response.status)
        this.errorNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);
      }
      
    })
    .catch(error => console.log(error));

    this.setState({
      article: "",
      price: "",
      description: ""
    });
  }

  handleMouseMove = e => {
    //console.log('handleMouseMove');
    this.hideNotifications();
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleTouchStart = e => {
    //console.log('handleTouchStart');
    this.hideNotifications();
    document.removeEventListener('touchstart', this.handleTouchStart);
  }

  hideNotifications () {
    //console.log('hideNotifications');
    this.feedbackNode.classList.add('hide');
    this.feedbackNode.classList.remove('show');
    this.sucessNode.style.display = 'none';
    this.errorNode.style.display = 'none';
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    //console.log(this.state)
    return (
      <div className="my-3 p-3 bg-white rounded shadow-sm no-margin-sm">
        <div className="text-muted">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="article">Article *</label>
              <input
                type="text"
                className="form-control"
                id="article"
                name="article"
                value={this.state.article}
                onChange={this.handleInputChange}
                required />
            </div>
            <div className="form-group">
              <label htmlFor="price">Prix *</label>
              <input
                type="number"
                className="form-control"
                id="price"
                name="price"
                value={this.state.price}
                onChange={this.handleInputChange}
                required />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                className="form-control"
                id="description"
                rows="3"
                name="description"
                value={this.state.description}
                onChange={this.handleInputChange}>
              </textarea>
            </div>

            <div className="btn-toolbar justify-content-end pt-3" role="toolbar" aria-label="Toolbar with button groups">
              <button type="submit" className="btn btn-outline-primary">
                Enregistrer
              </button>
            </div>

            <div className="form-feedback" ref={node => (this.feedbackNode = node)}>
              <div className="alert alert-success" role="alert" ref={node => (this.sucessNode = node)}><small>Entrée enregistrée.</small></div>
              <div className="alert alert-danger" role="alert" ref={node => (this.errorNode = node)}><small>Un erreur est survenu, veuillez réssayer.</small></div>
            </div>

          </form>
        </div>
      </div>
    );
  }
}

Custom.contextType = AuthContext;

export default Custom;
