import React, { Component } from 'react';
import { Route } from "react-router-dom";
import Header from './components/Header';
import Entry from './pages/Entry';
import Group from './pages/Group';
import History from './pages/History';
import ContentExport from './pages/content-export';
import Custom from './pages/Custom';
import Support from './pages/Support';
import DB from './helpers';


class Wrapper extends Component {

  constructor(props) {
    super(props);

    this.state = {
      history: {
        entries: [],
        custom: []
      }
    }
  }

  componentDidMount() {
    this.updateHistory();
  }

  updateHistory = () => {
    DB.getHistory().then((res) => {
      this.setState({
        history: res
      })
    });

  }

  render() {
    const { match } = this.props;
    return (
      <>
        <Header
          total={this.state.history.entries.length}
          user={(this.state.worker)?this.state.worker.name:''} />

        <main role="main" className="container">
          <Route path={match.path} exact render={(props) => (
            <Entry {...props}
              updateHistory={(i) => this.updateHistory(i)} />
          )} />
          <Route path="/group" exact render={(props) => (
            <Group {...props}
              updateHistory={(i) => this.updateHistory(i)} />
          )} />
          <Route path="/custom" exact render={(props) => (
            <Custom {...props}
              updateHistory={this.updateHistory} />
          )} />
          <Route path="/historique" exact render={(props) => (
            <History {...props}
              history={this.state.history}
              updateHistory={this.updateHistory} />
          )} />
          <Route path="/export" exact render={(props) => (
            <ContentExport {...props} />
          )} />
          <Route path="/support" exact render={(props) => (
            <Support {...props} />
          )} />
        </main>
      </>
    );
  }
}

export default Wrapper;
