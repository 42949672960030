import React, { Component } from 'react';

class InputDate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: "",
    }
    this.input = React.createRef();
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
  }

  onInputHandler = (e) => {
    //console.log('onInputHandler');
    const target = e.target;
    target.type = 'text';
    var input = target.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split('/').map(function(v) {
      return v.replace(/\D/g, '')
    });
    if (values[0]) values[0] = this.checkValue(values[0], 31);
    if (values[1]) values[1] = this.checkValue(values[1], 12);
    var output = values.map(function(v, i) {
      return v.length === 2 && i < 2 ? v + ' / ' : v;
    });
    //target.value = output.join('').substr(0, 14);
    const value = output.join('').substr(0, 14);
    this.setState({date: value})
  }

  onBlurHandler = (e) => {
    //console.log('onBlurHandler');
    const target = e.target;
    target.type = 'text';
    var input = target.value;
    var values = input.split('/').map(function(v, i) {
      return v.replace(/\D/g, '')
    });

    var output = null;
    if (values.length === 3) {
      var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
      var month = parseInt(values[1]) - 1;
      var day = parseInt(values[0]);
      var d = new Date(year, month, day);
      if (!isNaN(d)) {
        output = d;
      };
    };

    this.props.updateBirthdate(output, this.props.index);
  }

  checkValue(str, max) {
    if (str.charAt(0) !== '0' || str == '00') {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
    };
    return str;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log('componentDidUpdate')
    //console.log(this.props.birthdate);
    if(this.props.birthdate !== prevProps.birthdate) {

      let date = "";
      if(this.props.birthdate !== null) {
        date = this.convertDateObjToAppFormat(this.props.birthdate);
      }

      this.setState({date: date})
    }
  }

  convertDateObjToAppFormat(date) {
    //const date = new Date(string);
    const year = date.getFullYear();
    const month = ( date.getMonth() + 1 > 9 ) ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    const day = ( date.getDate() > 9 ) ? date.getDate() : "0" + date.getDate();
    return day + " / " + month + " / " + year;
  }

  render() {



    return (
      <div className="form-group">
        <label htmlFor={`inputBirthdate${this.props.index}`} className="col-form-label">
          Date de naissance * <small>(jj/mm/aaaa)</small>
        </label>
        <input
          type="text"
          className="form-control"
          id={`inputBirthdate${this.props.index}`}
          name="birthdate"
          min="1000-01-01"
          required
          value={this.state.date}
          onChange={this.onInputHandler}
          onBlur={this.onBlurHandler}
        />
      </div>

    );
  }
}

export default InputDate;
