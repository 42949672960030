import React, { Component } from 'react';
//import { DateRangePicker, DateRange } from 'react-date-range';
//import { fr } from 'date-fns/esm/locale';
import DB from '../helpers.js';


class ContentHistory extends Component {

  constructor() {
    super();
    this.state = {
      export: {
        startDate: new Date(),
        endDate: new Date(),
      }
    };

  }

  handleDatePicker= ranges => {

    if(!this.Alert.classList.contains('invisible')) {
      this.Alert.classList.add('invisible')
    }
    this.setState({
      export: {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      }
    });

	}

  handleExport= e => {
    e.preventDefault();
    let range = this.state.export;
    DB.exportDatabase(range);
  }

  render() {
    //console.log(this.state)
    return (
      <div className="d-flex flex-column my-3 bg-white rounded shadow-sm">
        {/*{window.innerWidth < 576 ? (
            <DateRange
              ranges={[{
                  startDate: this.state.export.startDate,
                  endDate: this.state.export.endDate,
                  key: 'selection',
                }]}
              onChange={this.handleDatePicker}
              locale={fr}
            />
          ):(
            <DateRangePicker
              ranges={[{
                  startDate: this.state.export.startDate,
                  endDate: this.state.export.endDate,
                  key: 'selection',
                }]}
              onChange={this.handleDatePicker}
              locale={fr}
            />
          )}*/}

        <div className="text-muted p-3">
          <div className="text-right">
            <button className="btn btn-primary" onClick={this.handleExport}>Exporter</button>
          </div>
          <div className="alert alert-info invisible"
               role="alert"
               id="no-results-export"
               ref={(node) => (this.Alert = node)}>
            Pas de résultats trouvés.
          </div>
        </div>

      </div>
    );
  }
}

export default ContentHistory;
