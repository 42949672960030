import React, { Component } from 'react';
// functional
class Support extends Component {

  render() {
    return (
      <main role="main" className="container">
        <div className="my-3 text-center support-lead">
          <p>Veuillez reporter tout problème ou suggestion à <a href="mailto:dev@1tec.org">dev@1tec.org</a>.</p>
          <p>
            <small>
              Dernière mise à jour le 29 mars 2023.
              <br />
              Update de l'API google sigin.
            </small>
          </p>
        </div>
      </main>
    )
  }
}

export default Support;
