import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import AuthContext from '../AuthContext';
import logo from '../assets/img/logo-h90.png';

class Header extends Component {

  render() {

    return (
      <AuthContext.Consumer>
        { auth => {
          return (
            <React.Fragment>
              <nav className="navbar fixed-top navbar-light bg-light">
                <a className="navbar-brand mr-auto mr-lg-0 text-muted" href="/">
                  <img src={logo} className="navbar-logo" alt="evolution verticale logo"/>
                  Permanences
                </a>

                  <div className="">
                    <div className="d-none d-md-inline-flex p-2">
                      {auth.getUserName()}
                    </div>
                    <button type="button"
                            className="btn btn-dark btn-sm"
                            onClick={auth.logOut}>Logout</button>
                  </div>

              </nav>

              <div className="nav-scroller bg-white shadow-sm">
                <nav className="nav nav-underline">
                  <NavLink to="/" className="nav-link" exact >Entrées</NavLink>
                  <NavLink to="/group" className="nav-link" exact >Famille</NavLink>
                  <NavLink to="/custom" className="nav-link" exact >Custom</NavLink>
                  <NavLink to="/historique/" className="nav-link" exact >
                      Historique
                      <span className="badge badge-pill bg-light align-text-bottom">{this.props.total}</span>
                  </NavLink>
                  {/*}<NavLink to="/export" className="nav-link disabled" exact >Export</NavLink>*/}
                  <NavLink to="/support" className="nav-link" exact >Support</NavLink>
                </nav>
              </div>
            </React.Fragment>
          )
        }}
      </AuthContext.Consumer>

    );
  }
}

export default Header;
