import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import AuthContext from '../AuthContext';

class History extends Component {

  handleCLick = e => {

    const items = document.getElementsByClassName('actionable');

    for (const item of items) {
      if(item === e.currentTarget) { continue; }
      item.classList.remove('actionable');
    }
    e.currentTarget.classList.toggle('actionable');
  }

  handleUpdate = (e, id) => {

    e.stopPropagation();

    const url = process.env.REACT_APP_API_URI + `/api/entries/update.php`;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({id: id}),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.context.getAccessToken()}`
      }
    })
    .then(response => {
      this.props.updateHistory();

      if(response.ok) {

        const items = document.getElementsByClassName('list-group-item');
        for (const item of items) {
          item.classList.remove('actionable');
        }

        this.exitNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);

      } else if (response.status === 401) {
        this.context.logOut();
      } else {
        this.errorNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);
      }
    })
    .catch( error =>
      console.log(error)
    ) 
  }

  handleDelete = (e, id, type) => {

    e.stopPropagation();

    const url = process.env.REACT_APP_API_URI + `/api/${type}/delete.php`;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({id: id}),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.context.getAccessToken()}`
      }
    })
    .then(response => {
      //console.log(response)
      this.props.updateHistory();

      if(response.ok){
        //console.log(response.status)
        const items = document.getElementsByClassName('list-group-item');
        for (const item of items) {
          item.classList.remove('actionable');
        }

        this.sucessNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);
          
      }else if(response.status === 401){
        //console.log(response.status)
        this.context.logOut();
      }else {
        //console.log(response.status)
        this.errorNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);
      }
      
    })
    .catch(error => console.log(error));                
  }

  handleMouseMove = e => {
    //console.log('handleMouseMove');
    this.hideNotifications();
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleTouchStart = e => {
    //console.log('handleTouchStart');
    this.hideNotifications();
    document.removeEventListener('touchstart', this.handleTouchStart);
  }

  hideNotifications () {
    //console.log('hideNotifications');
    this.feedbackNode.classList.add('hide');
    this.feedbackNode.classList.remove('show');
    this.sucessNode.style.display = 'none';
    this.errorNode.style.display = 'none';
  }

  render() {
    return (
    <>
      <div className="my-3 bg-white rounded shadow-sm">

        <ul className="list-group">
          {this.props.history.entries.map((entry, i) =>
            <li className="list-group-item" key={i} onClick={this.handleCLick}>
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{entry.last_name}, {entry.first_name}</h5>
                <small>
                  {entry.entry_at + ' - '}
                  {entry.entry_end
                    ? entry.entry_end
                    : <b className="text-success">présent</b>
                  }
                </small>
              </div>
              <div className="d-flex w-100 justify-content-between">
                <small>
                  {entry.entry_type}
                  {entry.shoes > 0 &&
                    " + chaussons"
                  }
                  {entry.shoes > 1 &&
                    ` (${entry.shoes})`
                  }
                </small>
                <small>{`${entry.price}€`}</small>
              </div>
              <div className={"w-100 actions-wrapper " + (entry.entry_end ? 'justify-content-end' : 'justify-content-between')}>
                {!entry.entry_end &&
                  <Button 
                  variant="link" 
                  size="sm" 
                  onClick={e => this.handleUpdate(e, entry.id)}
                >SORTIE</Button>
                }
                <Button
                  variant="link"
                  size="sm"
                  onClick={e => (this.handleDelete(e, entry.id, 'entries')) }
                >ANNULER</Button>
              </div>
            </li>
          )}
        </ul>

        

      </div>

      {this.props.history.custom.length > 0 &&
        <div className="my-3 bg-white rounded shadow-sm">
          <ul className="list-group">
            <li className="list-group-item bg-light">
              <h6 className="text-uppercase mb-0 ">Custom</h6>
            </li>
            {this.props.history.custom.map((custom, i) =>
              <li className="list-group-item" key={i} onClick={this.handleCLick}>
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">{custom.article}</h5>
                  <small>{custom.entry_at}</small>
                </div>
                <div className="d-flex w-100 justify-content-between">
                  <small>{custom.description}</small>
                  <small>{`${custom.price}€`}</small>
                </div>
                <div className="w-100 justify-content-end actions-wrapper">
                  {/*<Button variant="link" size="sm" onClick={this.handleUpdate}>MODIFIER</Button>*/}
                  <Button
                    variant="link"
                    size="sm"
                    onClick={e => (this.handleDelete(e, custom.id, 'shop')) }
                  >ANNULER</Button>
                </div>
              </li>
            )}
          </ul>
        </div>
      }
      

      <div className="form-feedback" ref={node => (this.feedbackNode = node)}>
        <div className="alert alert-success" role="alert" ref={node => (this.exitNode = node)}><small>Entrée terminée.</small></div>
        <div className="alert alert-success" role="alert" ref={node => (this.sucessNode = node)}><small>Entrée annulée.</small></div>
        <div className="alert alert-danger" role="alert" ref={node => (this.errorNode = node)}><small>Un erreur est survenu, veuillez réssayer.</small></div>
      </div>
    </>
    );
  }
}

History.contextType = AuthContext;

export default History;
