import loadScript from './loadScript';
import jwt_decode from "jwt-decode"

class Auth {

  constructor(history) {
    this.history = history;
  }

  init() {
    // console.log('init')

    return loadScript('https://accounts.google.com/gsi/client')
  }

  initAuth() {
    //console.log('initAuth')

    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
      hosted_domain: process.env.REACT_APP_GOOGLE_OAUTH_HOSTED_DOMAIN, // is this working properly ?
      callback: this.handleCredentialResponse,
    })
  }

  async renderSignInButton (parent) {
    // console.log("renderSignInButton");

   if (!window.google) {
    try {
      await this.init()
      this.initAuth()
    } catch(error) {
      console.error(error);
    }
   }

    window.google.accounts.id.renderButton(parent, {locale: "fr"})
  }

  handleCredentialResponse = (response) => {
    // console.log('handleCredentialResponse')

    const responsePayload = jwt_decode(response.credential)
    if (responsePayload.hd != process.env.REACT_APP_GOOGLE_OAUTH_HOSTED_DOMAIN) {
      return
    }
 
    this.logIn(response.credential)
    
  }

  logIn(idToken) {
    //console.log('logging in')

    this.createSession(idToken)
    this.history.push("/")
  }

  logOut = () => {
    //console.log('logging out')
    
    this.deleteSession()
    this.history.push("/login")
    this.renderSignInButton()

  }

  createSession(idToken) {
    //console.log("createSession");
    const payload = jwt_decode(idToken)

    localStorage.setItem('id_token', idToken)
    localStorage.setItem('expires_at', payload.exp * 1000)
    localStorage.setItem('username', payload.name)
  }

  deleteSession() {
    //console.log("deleting session");

    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('username')
    
  }

  isSignedIn() {
    //console.log('isSignedIn')
    // on every page I need to check if user is sign in
    const expiresAt = localStorage.getItem('expires_at');
    return new Date().getTime() < expiresAt
  }

  getAccessToken() {
    return localStorage.getItem("id_token");
  }

  getUserName() {
    return localStorage.getItem("username");
  }

}

export default Auth;
