import fileDownload from 'js-file-download';

class DB {

  async getHistory() {
    return {
      entries: await this.getEntriesHistory(),
      custom: await this.getCustomHistory(),
    }
  }

  getEntriesHistory() {

    const url = process.env.REACT_APP_API_URI + "/api/entries/history.php";

    return fetch(url)
          .then(res => (res.status === 200)? res.json() : [] )
          .then(
            (result) => {
              return result;
            },
            (error) => {
              console.log(error);
            }
          );

  }

  getCustomHistory() {

    const url = process.env.REACT_APP_API_URI + "/api/shop/history.php";

    return fetch(url)
          .then(res => (res.status === 200)? res.json() : [] )
          .then(
            (result) => {
              return result;
            },
            (error) => {
              console.log(error);
            }
          );

  }

  async searchUser(user) {

    const url = process.env.REACT_APP_API_URI + "/api/users/search.php?f="+user.first_name+"&l="+user.last_name;

    return fetch(url)
      .then(res => (res.status === 200)? res.json() : [])
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log(error);
        }
      )
  }

  async getUser(id) {

    const url = process.env.REACT_APP_API_URI + "/api/users/get.php?i="+id;

    return fetch(url)
      .then(res => (res.status === 200)? res.json() : null)
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log(error);
        }
      )
  }

  exportDatabase (range) {

    let start = range.startDate.getTime() / 1000;
    let end = range.endDate.getTime() / 1000;
    const url = process.env.REACT_APP_API_URI + "/api/entries/export.php?start="+start+"&end="+end;

    fetch(url)
      //.then(res => (res.status === 200)? res.json() : null )
      .then(res => {
        return res.blob();
      } )
      .then(
        (result) => {
          if(result !== null) {
            fileDownload(result, 'export.csv');
          } else {
            document.getElementById('no-results-export').classList.remove('invisible');
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }
}

export default new DB();
