const loadScript = (url) => new Promise((resolve, reject) => {

  if (!document) {
    reject(new Error('Document was not defined'));
  }

  const scriptTag = document.createElement('script');
  scriptTag.src = 'https://accounts.google.com/gsi/client';
  scriptTag.async = true;
  scriptTag.defer = true;

  scriptTag.onload = () => resolve(true)

  scriptTag.onerror = (msg) => {
    console.log(msg);
    reject(new Error('Error loading script.'));
  };

  scriptTag.onabort = (msg) => {
    console.log(msg);
    reject(new Error('Script loading aborted.'));
  };

  document.body.appendChild(scriptTag);
});


export default loadScript;
