//import $ from "jquery";
import React, { Component } from 'react';
import GroupAccordion from '../components/GroupAccordion';
import AuthContext from '../AuthContext';
import Octicon, {PlusSmall, Dash} from '@primer/octicons-react';
import PriceConfirmation from '../components/PriceConfirmation';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Group extends Component {

  constructor() {
    super();
    this.state = {
      users: [
        {
          account_id: null,
          first_name: "",
          last_name: "",
          birthdate: null,
          phone: "",
          email: "",
          member: false,
        },
        {
          account_id: null,
          first_name: "",
          last_name: "",
          birthdate: null,
          phone: "",
          email: "",
          member: false,
        }
      ],
      entry_type: "famille",
      shoes: 0,
      maxusers: 4,
      minusers: 2,
      price: 15,
      showModal: false,
      reader: {
        legacyMode: false
      }
    };

    this.shoesPrice = 2;

  }

/* 
* Handlers/State 
*/
inputNumberHandler = (value) => {

  const shoes = this.state.shoes + value;

  if (shoes >= 0 && shoes <= 4 ) {
    this.setState({
      'shoes' : shoes
    });
  }
  
}

/* 
* Logic
*/
getEntryPrice() {
  let price = this.state.price;

  if(this.state.shoes > 0) {
    price += this.shoesPrice * this.state.shoes
  }

  return price;
}

  /* Used on Email Input */
  handleInputFamilyChange = (e, index) => {
    //console.log('handleInputFamilyChange')

    let newUsersArray = [...this.state.users];
    const newUser = Object.assign( {}, this.state.users[index], {email: e.target.value} );

    newUsersArray[index] = newUser;

    this.setState({
      users: newUsersArray
    });
  }

  updateFamily = ({user, index}) => {

    let newUsersArray = [...this.state.users];
    const newUser = Object.assign( {}, this.state.users[index], user );

    newUsersArray[index] = newUser;

    this.setState({
      users: newUsersArray
    });

  }

  convertDateObjToApiFormat(date) {
    const year = date.getFullYear();
    const month = ( date.getMonth() + 1 > 9 ) ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    const day = ( date.getDate() > 9 ) ? date.getDate() : "0" + date.getDate();
    return year + '-' + month + '-' +day;
  }

  handleSubmit = e => {
    //console.log('handleSubmit')
    e.preventDefault();
    this.setState({ showModal: true });
  }

  submit() {
    //console.log('submit')
    const url = process.env.REACT_APP_API_URI + "/api/entries/create.php";
    const worker = this.context.getUserName();
    const users = this.state.users.map( user => {
      return Object.assign( {}, user, {birthdate: this.convertDateObjToApiFormat(user.birthdate)} );
    });

    let doc = {
      account_id: users[0].account_id,
      first_name: users[0].first_name,
      last_name: users[0].last_name,
      birthdate: users[0].birthdate,
      phone: users[0].phone,
      email: users[0].email,
      member: users[0].member,
      entry_type: this.state.entry_type,
      shoes: this.state.shoes,
      price: this.getEntryPrice(),
      family: users.slice(1),
      worker: worker
    }
    // shoul make different on the backend, some how this is only one entry with people
    // that should be showed as only one entry in history

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(doc),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.context.getAccessToken()}`
      }
    })
    .then(response => {

      this.props.updateHistory();
      
      if(response.ok){
        //console.log(response.status)
        this.sucessNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);
          
      }else if(response.status === 401){
        //console.log(response.status)
        this.context.logOut();
      }else {
        //console.log(response.status)
        this.errorNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);
      }
      
    })
    .catch(error => console.log(error));

    // Reset state
    this.resetState();
  }

  handleMouseMove = e => {
    //console.log('handleMouseMove');
    this.hideNotifications();
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleTouchStart = e => {
    //console.log('handleTouchStart');
    this.hideNotifications();
    document.removeEventListener('touchstart', this.handleTouchStart);
  }

  hideNotifications () {
    //console.log('hideNotifications');
    this.feedbackNode.classList.add('hide');
    this.feedbackNode.classList.remove('show');
    this.sucessNode.style.display = 'none';
    this.errorNode.style.display = 'none';
  }

  /*toggleModal = e => {
    this.setState({
      showModal: !this.state.showModal
    })
  }*/

  updateBirthdateChild = (date, index) => {
    //console.log("updateBirthdateChild")
    let newUsersArray = [...this.state.users];
    const newUser = Object.assign( {}, this.state.users[index], {birthdate: date} );

    newUsersArray[index] = newUser;

    this.setState({
      users: newUsersArray
    });
  }

  updatePhoneChild = (value, index) => {
    //console.log("updateBirthdateChild")
    let newUsersArray = [...this.state.users];
    const newUser = Object.assign( {}, this.state.users[index], {phone: value} );

    newUsersArray[index] = newUser;

    this.setState({
      users: newUsersArray
    });
  }

  addUser = e => {
    const usersLength = this.state.users.length;
    const newUser = {
      account_id: null,
      first_name: "",
      last_name: "",
      birthdate: null,
      phone: "",
      email: "",
      member: false,
    };

    if(usersLength < this.state.maxusers) {
      let newUsersArray = [...this.state.users, newUser];
      this.setState({ users: newUsersArray });
    }
  }

  removeUser = e => {
    const usersLength = this.state.users.length;

    if(usersLength > this.state.minusers) {
      let newUsersArray = this.state.users.slice(0, -1);
      this.setState({ users: newUsersArray });
    }
  }

  resetState = e => {
    this.setState({
      users: [
        {
          account_id: null,
          first_name: "",
          last_name: "",
          birthdate: null,
          phone: "",
          email: "",
          member: false,
        },
        {
          account_id: null,
          first_name: "",
          last_name: "",
          birthdate: null,
          phone: "",
          email: "",
          member: false,
        }
      ],
      shoes: 0
    });
  }

  handleModalClose = () => {
    this.setState({ showModal: false });
  }

  handleModalConfirmation = () => {
    this.setState({
      showModal: false
    });
    this.submit();
  }

  render() {
    return (
      <>
        <div className="my-3 p-3 bg-white rounded shadow-sm no-margin-sm">

          <div className="text-muted">
            <form onSubmit={this.handleSubmit}>
              <div className="btn-toolbar justify-content-between align-items-center mb-3" role="toolbar" aria-label="toolbar with button add/remove person">
                <p className="pl-3 mb-0"><strong>15€ (max 4p)</strong></p>
                <div className="btn-group" role="group" aria-label="Third group">
                  <button type="button" className="btn btn-secondary" onClick={this.removeUser}>
                    <Octicon icon={Dash}/>
                  </button>
                  <div className="btn btn-secondary disabled">{this.state.users.length}</div>
                  <button type="button" className="btn btn-secondary" onClick={this.addUser}>
                    <Octicon icon={PlusSmall}/>
                  </button>
                </div>
              </div>

              <GroupAccordion
                users={this.state.users}
                updateUser={this.updateFamily}
                updateBirthdate={this.updateBirthdateChild}
                updatePhone={this.updatePhoneChild}
                onChange={this.handleInputFamilyChange}
              />

              <Row className="justify-content-around align-items-center mb-3">
                  <Col className="col-form-label">Chaussons (2€)</Col>
                  <Col xs="auto">
                      <div className="btn-group" role="group" aria-label="Third group">
                        <button type="button" className="btn btn-secondary" onClick={ (e) => this.inputNumberHandler(-1) }>
                          <Octicon icon={Dash}/>
                        </button>
                        <div className="btn btn-secondary disabled">{this.state.shoes}</div>
                        <button type="button" className="btn btn-secondary" onClick={(e) => this.inputNumberHandler(1)}>
                          <Octicon icon={PlusSmall}/>
                        </button>
                      </div>
                  </Col>
              </Row>

              <div className="btn-toolbar justify-content-between pt-3" role="toolbar" aria-label="Toolbar with button groups">
                <button type="button" className="btn btn-outline-primary" onClick={this.resetState}>
                  Annuler
                </button>
                <button type="submit" className="btn btn-outline-primary">
                  Enregistrer
                </button>
              </div>

              <div className="form-feedback" ref={node => (this.feedbackNode = node)}>
                <div className="alert alert-success" role="alert" ref={node => (this.sucessNode = node)}><small>Entrée enregistrée.</small></div>
                <div className="alert alert-danger" role="alert" ref={node => (this.errorNode = node)}><small>Un erreur est survenu, veuillez réssayer.</small></div>
              </div>

            </form>

          </div>

        </div>

        <PriceConfirmation 
          show={this.state.showModal}
          price={this.getEntryPrice()}
          onConfirmation={this.handleModalConfirmation}
          onClose={this.handleModalClose}
        />

        {/*<Reader updateUser={this.updateUser}
                show={this.state.showModal}
                close={this.toggleModal} />*/}

      </>
    );
  }
}

Group.contextType = AuthContext;

export default Group;
