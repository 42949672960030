import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class PriceConfirmation extends Component {
    render() {
        return(
            <Modal show={this.props.show} size="sm" centered onHide={this.props.onClose} >
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Entrée Payante</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {`Veuillez encaisser ${this.props.price}€.`}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={this.props.onConfirmation}>Confirmer</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PriceConfirmation;