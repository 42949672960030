//import $ from "jquery";
import React, { Component } from 'react';
//import Octicon, {ChevronDown, ChevronUp} from '@primer/octicons-react';
import Identifier from '../components/Identifier';
import InputDate from '../components/InputDate';
import AuthContext from '../AuthContext';
import PriceConfirmation from '../components/PriceConfirmation';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

class Entry extends Component {

  constructor() { 
    super();
    this.state = {
      user: {
        account_id: null,
        first_name: "",
        last_name: "",
        birthdate: null,
        phone: "",
        email: "",
        member: false,
        abloc: 0,
      },
      shoes: 0,
      entry_type: null,
      price: 0,
      entriesThisMonth: 0,
      showModal: false,
      isConfirmed: false,
      
      //dropdown: false, // needs own component
    };

    this.priceList = {
      membre: 0,
      assureur: 0,
      grimpeur: {
        young: 5,
        adult: 8
      },
      abloc: 0,
      grimpeur_jeune: 5,
      grimpeur_adult: 8,
    }

    this.shoesPrice = 2;
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.state.user.abloc && !prevState.user.abloc) {
      this.getEntriesThisMonth();
    }
  }

  /* 
  * Logic/State 
  */
  toggleButtonHandler = e => {

    this.setState({
      [e.currentTarget.name] : ( this.state.shoes > 0 ) ? 0 : 1
    });

  }

  /* 
  * Frontend Actions 
  */

  /* Used on Email Input */
  handleInputChange = e => {

    const newUser = Object.assign( {}, this.state.user, {[e.target.name]: e.target.value} );

    if(e.target.name === "entry_type" && e.target.value === 'famille') {
      newUser.family.push({
        account_id: null,
        first_name: "",
        last_name: "",
        birthdate: null,
        email: "",
        member: null,
      });
    }

    this.setState({
      user: newUser
    });

  }

  handleCheckboxChange = e => {

    const member = (e.target.value === "membre") ? true : false;

    this.setState({
      user: Object.assign( {} , this.state.user, { member: member } ),
      entry_type: e.target.value
    });

  }

  convertDateObjToApiFormat(date) {

    const year = date.getFullYear();
    const month = ( date.getMonth() + 1 > 9 ) ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    const day = ( date.getDate() > 9 ) ? date.getDate() : "0" + date.getDate();
    return year + '-' + month + '-' +day;
  }

  getAge() {
    const birthdate = this.state.user.birthdate;
    const today = new Date();

    let age = today.getFullYear() - birthdate.getFullYear();
    if( today.getMonth() < birthdate.getMonth() ||
        ( today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate() )
      ) {
      age = age - 1;
    }

    return (age < 23)? 'young' : 'adult';
  }

  getEntryPrice() {

    if( this.state.entry_type && this.state.user.birthdate) {

      const entry_type = this.state.entry_type;
      let price = this.priceList[entry_type];

      if(typeof price == 'object') {
        const age = this.getAge();
        price = this.priceList[entry_type][age];
      }

      if(this.state.entry_type === "grimpeur" && this.state.user.abloc && this.state.entriesThisMonth == 0) {
        price = this.priceList['abloc'];
      }

      if(this.state.shoes > 0) {
        price = price + this.shoesPrice;
      }

      return price;
    }

    return false;

  }

  getEntriesThisMonth() {
    const url = process.env.REACT_APP_API_URI + "/api/entries/month.php";
    const worker = this.context.getUserName();

    let doc = {
      account_id: this.state.user.account_id,
    };

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(doc),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.context.getAccessToken()}`
      }
    }).then(response => {
      if(!response.ok) {
        throw new Error('Network response was not OK');
      }
      return response.json();
    }).then(data => {
      this.setState({ entriesThisMonth: data.count });
    }).catch(error => console.log(error));

  }

  handleSubmit = e => {

    e.preventDefault();

    if( this.state.shoes > 0 || this.state.entry_type === "grimpeur" && !this.state.isConfirmed) {
      this.setState({
        price: this.getEntryPrice(),
        showModal: true
      });
    } else {
      this.submit();
    }

  }

  submit() {

    const url = process.env.REACT_APP_API_URI + "/api/entries/create.php";
    const worker = this.context.getUserName();

    let doc = {
      account_id: this.state.user.account_id,
      first_name: this.state.user.first_name,
      last_name: this.state.user.last_name,
      birthdate: this.convertDateObjToApiFormat(this.state.user.birthdate),
      phone: this.state.user.phone,
      email: this.state.user.email,
      member: this.state.user.member,
      abloc: this.state.user.abloc,
      entry_type: this.state.entry_type,
      shoes: this.state.shoes,
      price: this.state.price,
      family: null,
      worker: worker
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(doc),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.context.getAccessToken()}`
      }
    })
    .then(response => {
      
      this.props.updateHistory();

      if(response.ok){
        //console.log(response.status)
        this.sucessNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);
          
      }else if(response.status === 401){
        //console.log(response.status)
        this.context.logOut();
      }else {
        //console.log(response.status)
        this.errorNode.style.display = 'block';
        this.feedbackNode.classList.add('show');
        this.feedbackNode.classList.remove('hide');

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('touchstart', this.handleTouchStart);
      }
      
    })
    .catch(error => console.log(error));

    // Reset state
    this.resetState();
  }

  handleMouseMove = e => {
    //console.log('handleMouseMove');
    this.hideNotifications();
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleTouchStart = e => {
    //console.log('handleTouchStart');
    this.hideNotifications();
    document.removeEventListener('touchstart', this.handleTouchStart);
  }

  hideNotifications () {
    //console.log('hideNotifications');
    this.feedbackNode.classList.add('hide');
    this.feedbackNode.classList.remove('show');
    this.sucessNode.style.display = 'none';
    this.errorNode.style.display = 'none';
  }

  updateUser= ({user}) => {
    const newUser = Object.assign( {}, this.state.user, user );
    this.setState({
      user: newUser,
      entry_type: (newUser.member)?"membre":null
    });
  }

  /*toggleModal = e => {
    this.setState({
      showModal: !this.state.showModal
    })
  }*/

  updateBirthdate = (date) => {
    this.setState({
      user: Object.assign( {}, this.state.user, {birthdate: date} )
    });


  }

  dropdownToggle = e => {
    this.setState({
      dropdown: !this.state.dropdown
    })
  }

  resetState = e => {
    this.setState({
      user: {
        account_id: null,
        first_name: "",
        last_name: "",
        birthdate: null,
        phone: "",
        email: "",
        member: false,
        abloc: 0
      },
      shoes: 0,
      entry_type: null,
      price: 0,
      entriesThisMonth: 0,
      showModal: false,
      isConfirmed: false
    });
  }

  handleModalClose = () => {
    this.setState({ showModal: false });
  }

  handleModalConfirmation = () => {
    this.setState({
      showModal: false,
      isConfirmed: true,
    });
    this.submit();
  }

  updatePhone = e => {
    this.setState({
      user: Object.assign( {}, this.state.user, {phone: e.target.value} )
    });
  }

  updateAbloc = e => {
    this.setState({
      user: Object.assign( {}, this.state.user, {abloc: !this.state.user.abloc} )
    });
  }


  render() {
    return (
      <React.Fragment>
        <div className="my-3 p-3 bg-white text-muted rounded shadow-sm no-margin-sm">


            <form onSubmit={this.handleSubmit}>

              <Identifier
                user={this.state.user}
                updateUser={this.updateUser}
              />

              <InputDate
                updateBirthdate={this.updateBirthdate}
                birthdate={this.state.user.birthdate}
              />

              <div className="form-group">
                <label htmlFor="phone" className="col-form-label">
                  Téléphone *
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  required
                  value={this.state.user.phone}
                  onChange={this.updatePhone}
                />
              </div>

              <Row className="align-items-center mb-3">
                  <Col className="col-form-label">Location de chaussons (2€)</Col>
                  <Col xs="auto">
                    <ButtonGroup toggle>
                        <ToggleButton
                          size="sm"
                          type="checkbox"
                          name="shoes"
                          variant={ (this.state.shoes > 0 )? "primary": "outline-dark" }
                          checked={ ( this.state.shoes > 0 )? true : false }
                          value={this.state.shoes}
                          onChange={this.toggleButtonHandler}
                        >
                          { ( this.state.shoes > 0) ? "Oui": "Non" }
                        </ToggleButton>
                    </ButtonGroup>
                  </Col>
              </Row>

              {this.state.entry_type === "grimpeur" &&
                <Row className="align-items-center mb-3">
                  <Col className="col-form-label">Membre Abloc</Col>
                  <Col xs="auto">
                    <ButtonGroup toggle>
                      <ToggleButton
                          size="sm"
                          type="checkbox"
                          name="abloc"
                          variant={ (this.state.user.abloc > 0 )? "primary": "outline-dark" }
                          checked={ ( this.state.user.abloc > 0 )? true : false }
                          value={this.state.user.abloc}
                          onChange={this.updateAbloc}
                      >
                        { ( this.state.user.abloc > 0) ? "Oui": "Non" }
                      </ToggleButton>
                    </ButtonGroup>
                  </Col>
                </Row>
              }

              {/* Entry Type Checkbox */}
              <div className="btn-group fullwidth mb-3" role="group" aria-label="entry type selection">
                <label className={this.state.entry_type === "membre" ? "btn btn-primary" : "btn btn-secondary"} htmlFor="radioMembre">
                  Membre
                  <input  type="radio"
                          className="custom-control-input"
                          id="radioMembre"
                          name="entry_type"
                          value="membre"
                          required
                          checked={this.state.entry_type === "membre"}
                          onChange={this.handleCheckboxChange}
                    />
                </label>
                <label className={this.state.entry_type === "assureur" ? "btn btn-primary" : "btn btn-secondary"} htmlFor="radioAssureur">
                  Assureur
                  <input  type="radio"
                          className="custom-control-input"
                          id="radioAssureur"
                          name="entry_type"
                          value="assureur"
                          required
                          checked={this.state.entry_type === "assureur"}
                          onChange={this.handleCheckboxChange}
                    />
                </label>
                <label className={this.state.entry_type === "grimpeur" ? "btn btn-primary" : "btn btn-secondary"} htmlFor="radioGrimpeur">
                  Grimpeur
                  <input  type="radio"
                          className="custom-control-input"
                          id="radioGrimpeur"
                          name="entry_type"
                          value="grimpeur"
                          required
                          checked={this.state.entry_type === "grimpeur"}
                          onChange={this.handleCheckboxChange}
                    />
                </label>
              </div>

              

              {/* Input email  component
              <div className="form-group dropdown">
                <label htmlFor="inputEmail" className="col-form-label" onClick={this.dropdownToggle}>
                  Email <small>(optionnel)</small>
                  <Octicon icon={this.state.dropdown ? ChevronUp : ChevronDown}/>
                </label>
                {this.state.dropdown &&
                  <input type="email"
                         className="form-control"
                         name="email"
                         id="inputEmail"
                         placeholder="Entrer email"
                         value={this.state.user.email}
                         onChange={this.handleInputChange}
                   />
                }
              </div>*/}

              <div className="btn-toolbar justify-content-between pt-3" role="toolbar" aria-label="Toolbar with button groups">
                <button type="button" className="btn btn-outline-primary" onClick={this.resetState}>
                  Annuler
                </button>
                <button type="submit" className="btn btn-outline-primary">
                  Enregistrer
                </button>
              </div>

              <div className="form-feedback" ref={node => (this.feedbackNode = node)}>
                <div className="alert alert-success" role="alert" ref={node => (this.sucessNode = node)}><small>Entrée enregistrée.</small></div>
                <div className="alert alert-danger" role="alert" ref={node => (this.errorNode = node)}><small>Un erreur est survenu, veuillez réssayer.</small></div>
              </div>

            </form>



        </div>

        <PriceConfirmation 
          show={this.state.showModal}
          price={this.state.price}
          onConfirmation={this.handleModalConfirmation}
          onClose={this.handleModalClose}
        />

        {/*<div className="d-flex justify-content-end">
          <button type="button" className="btn btn-primary btn-sm mr-3" onClick={this.toggleModal}>QR</button>
          <button type="submit" className="btn btn-outline-primary">Enregistrer</button>
        </div>*/}

        {/*<Reader updateUser={this.updateUser}
                show={this.state.showModal}
                close={this.toggleModal} />*/}

      </React.Fragment>
    );
  }
}

Entry.contextType = AuthContext;

export default Entry;
