import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';
import DB from '../helpers.js';

class Identifier extends Component {
  constructor() {
    super()
    this.state = {
      suggestions: [],
    };
  }

  /* get suggestions from DB */
  onSuggestionsFetchRequestedFirstName = ({ value, reason }) => {
    //console.log('onSuggestionsFetchRequestedFirstName')
    if(reason === 'input-changed') {
        const newUser = Object.assign( {}, this.props.user, {first_name: value} );
        DB.searchUser( newUser )
          .then((result) => {
            //console.log(result)
            this.setState({
              suggestions: result
            })
          });
    }
  };
  /* get suggestions from DB */
  onSuggestionsFetchRequestedLastName = ({ value, reason }) => {
    //console.log('onSuggestionsFetchRequestedLastName')
    if(reason === 'input-changed') {
        const newUser = Object.assign( {}, this.props.user, {last_name: value} );
        DB.searchUser( newUser )
          .then((result) => {
            //console.log(result)
            this.setState({
              suggestions: result
            })
          });
    }
  };
  /* update user on parent state */
  getSuggestionValueFirstName = suggestion => {

    const newUser = Object.assign( {}, this.props.user, suggestion );
    newUser.birthdate = new Date(newUser.birthdate);

    let args = {
      user: newUser,
      index: this.props.index
    }
    this.props.updateUser(args);

  }
  /* update user on parent state */
  getSuggestionValueLastName = suggestion => {
    //console.log('getSuggestionValueLastName')
    const newUser = Object.assign( {}, this.props.user, suggestion );
    newUser.birthdate = new Date(newUser.birthdate);

    let args = {
      user: newUser,
      index: this.props.index
    }
    this.props.updateUser(args);

  }

  onChange = (event, { newValue }) => {
    //console.log('onChange')
    if(typeof event.target.name !== "undefined") {

      let args = {
        user: {[event.target.name]: newValue},
        index: this.props.index
      }
      this.props.updateUser(args);
    }

  };

  convertDateStringToAppFormat(string) {
    const date = new Date(string);
    const year = date.getFullYear();
    const month = ( date.getMonth() + 1 > 9 ) ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    const day = ( date.getDate() > 9 ) ? date.getDate() : "0" + date.getDate();
    return day + " / " + month + " / " + year;
  }

  renderSuggestion = (suggestion, { query }) => {
    //console.log('renderSuggestion')
    const suggestionText = `${suggestion.first_name} ${suggestion.last_name}`;
    const matches = Match(suggestionText, query);
    const parts = Parse(suggestionText, matches);
    //console.log(suggestion)

    return (
      <div className="media-body mb-0 small lh-125">
        <div className="d-flex justify-content-between align-items-center w-100">
          <strong className="text-gray-dark">
            {parts.map((part, index) => {
              return (<span className={part.highlight?'highlight':null} key={index}>{part.text}</span>)
            })}
          </strong>
        </div>
        <span className="d-block">{ suggestion.member ? 'member' : 'no member' } . {this.convertDateStringToAppFormat(suggestion.birthdate)}</span>
      </div>
    );
  }

  onSuggestionsClearRequested = () => {
    //console.log('onSuggestionsClearRequested')
    this.setState({
      suggestions: []
    });
  };

  render() {

    const inputPropsFirstName = {
      name: "first_name",
      className: "form-control",
      placeholder: 'Entrer prénom',
      value: this.props.user.first_name,
      onChange: this.onChange,
      required: true,
      autoComplete: 'no',
    };

    const inputPropsLastName = {
      name: "last_name",
      className: "form-control",
      placeholder: 'Entrer nom',
      value: this.props.user.last_name,
      onChange: this.onChange,
      required: true,
      autoComplete: 'no'
    };

    return (
      <React.Fragment>
        <div className="form-group">
          {/*<label htmlFor="inputFirstName" className="col-form-label">Prénom *</label>*/}
            <Autosuggest id="cont_first_name"
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedFirstName}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValueFirstName}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputPropsFirstName}
            />
        </div>
        <div className="form-group">
          {/*<label htmlFor="inputLastName" className="col-form-label">Nom *</label>*/}
             <Autosuggest id="cont_last_name"
               suggestions={this.state.suggestions}
               onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedLastName}
               onSuggestionsClearRequested={this.onSuggestionsClearRequested}
               getSuggestionValue={this.getSuggestionValueFirstName}
               renderSuggestion={this.renderSuggestion}
               inputProps={inputPropsLastName}
             />
        </div>
      </React.Fragment>
    )

  }
}

export default Identifier;
