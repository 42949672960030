import React, { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import UserForm from './UserForm';

class GroupAccordion extends Component {

  handleCollapse = e => {
    //console.log(e)
  }

  render() {
    
    return (
      <Accordion defaultActiveKey="0" className="mb-3">
        {this.props.users.map((n,i) => {
          return (
            <Card key={i}>
              <Accordion.Toggle as={Card.Header} eventKey={`${i}`}>
                Personne #{i + 1}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={`${i}`} onExit={this.handleCollapse}>
                <Card.Body>
                  <UserForm
                    index={i}
                    user={n}
                    updateUser={this.props.updateUser}
                    updateBirthdate={this.props.updateBirthdate}
                    updatePhone={this.props.updatePhone}
                    onChange={this.props.onChange}
                    />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        })}
      </Accordion>
    )
  }
}

export default GroupAccordion;
